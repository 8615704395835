import React, { Component } from 'react'
import { BusinessType } from '../components/OnBoarding'

class OnBoardingBusinessType extends Component {
  render() {
    return (
      <div>
        <BusinessType />
      </div>
    )
  }
}

export default OnBoardingBusinessType
